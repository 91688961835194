


































import { Component, Prop, Vue } from "vue-property-decorator";
import { MembershipStatus, statusIsActive } from "@/modules/membership/status";

@Component
export default class MembershipStatusFaq extends Vue {
  @Prop() private membershipStatus!: MembershipStatus;
  @Prop() private email?: string;

  get membershipIsActive() {
    return statusIsActive(this.membershipStatus);
  }

  get missingOrExpired() {
    return (
      this.membershipStatus === "Missing" || this.membershipStatus === "Expired"
    );
  }

  get waiverNotCurrent() {
    return (
      this.membershipStatus === "Missing Waiver" ||
      this.membershipStatus === "Waiver Expired"
    );
  }
}
