






























































import { Component, Prop, Vue } from "vue-property-decorator";

import moment, { Moment } from "moment";
import "@/filters";

import {
  MembershipStatus,
  expirationIfRenewingToday,
} from "@/modules/membership/status";
import { localize } from "@/modules/dateutil";
import { MembershipData } from "@/modules/membership/actions";

@Component
export default class MembershipStatusSummaryPersonal extends Vue {
  @Prop() private data!: MembershipData;

  get membershipStatus(): MembershipStatus {
    return this.data.membershipStatus;
  }

  get renewalValidUntil(): Moment {
    return expirationIfRenewingToday(this.data.membership.expires);
  }
}
